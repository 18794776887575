import { NgModule } from '@angular/core';

import {AuthGuardService} from "./shared/services/auth-guard.service"
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {path:"admin",
  canActivate: [AuthGuardService],
  loadChildren:()=>import('./admin/admin.module').then(m=>m.AdminModule)},
  // frontend lazy loading
 
{path:"",loadChildren:()=>import('./auth/auth.module').then(m=>m.AuthModule)}
];
@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
